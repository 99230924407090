import axios from 'axios'
//引入进度条插件
import nprogress from 'nprogress'
//引入进度条样式
import 'nprogress/nprogress.css'
//引入当前环境的接口域名
import configObj from '@/config'
//引入提示组件
import { Message } from 'element-ui'
//引入路由
import router from '@/router'
// 生成随机字符串
import { v4 as uuidv4 } from 'uuid'
// 引入MD5加密
import Crypto from '@/utils/crypto.js'
// 引入vuex
import store from '@/store/index'
// vue-cookies配置
import VueCookies from 'vue-cookies'

const baseURL = configObj.ApiUrl
const messageUrl = configObj.messageUrl
export async function request(config) {
    if (!VueCookies.get('timeDifference')) {
        // let timeRes = await axios.post(baseURL+'/sys/sysTime/getServerTime')
        let timeRes = await axios({
            url: baseURL + '/sys/sysTime/getServerTime',
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        })
        if (timeRes.data.code != 200) return Message.error({ message: timeRes.data.msg, grouping: true })
        if (Number(timeRes.data.data.time) - Date.parse(new Date()) > 3600000) {
            Message.warning({ message: '当前系统时间过慢，请重新设置，以免访问出错！', grouping: true })
        }
        VueCookies.set('timeDifference', Number(timeRes.data.data.time) - Date.parse(new Date()))
        return getAxios(config)
    } else {
        return getAxios(config)
    }
}
function getAxios(config) {
    // 创建axios的实例
    const instance = axios.create({
        baseURL: baseURL,
        timeout: 30000
    })

    instance.interceptors.request.use(
        (config) => {
            let timestamp = Date.parse(new Date()) + Number(VueCookies.get('timeDifference'))
            let noneStr = uuidv4()
            let token = VueCookies.get('token') ? VueCookies.get('token') : ''
            //进度条开启
            nprogress.start()
            //设置token请求头，确保在发送请求的时候有权限
            config.headers.token = token
            config.headers.timestamp = timestamp
            config.headers.noneStr = noneStr
            config.headers.signature = Crypto.EncryptMd5(noneStr + '#@||@#' + timestamp + '#@||@#' + token)
            // 拦截判断当前请求是否是消息系统，如果是则在请求头加上对应参数
            if (config.baseURL == messageUrl) {
                config.headers.userType = 'sysuser'
            }
            return config
        },
        (err) => {
            console.log(err)
        }
    )

    instance.interceptors.response.use(
        (res) => {
            //进度条结束
            nprogress.done()
            switch (Number(res.data.code)) {
                case 1001:
                    Message.closeAll()
                    Message({ type: 'error', message: res.data.msg })
                    break
                case 1004:
                    Message.closeAll()
                    Message({ type: 'error', message: res.data.msg })
                    break
                case 2001:
                    Message.closeAll()
                    Message({ type: 'error', message: res.data.msg })
                    VueCookies.remove('token')
                    VueCookies.remove('activePath')
                    VueCookies.remove('menuItem')
                    VueCookies.remove('userInfo')
                    store.commit('renewUserInfo', {})
                    store.commit('renewMenuItem', {})
                    VueCookies.remove('activeIndex')
                    store.commit('changeActiveIndex', '')
                    if (router.currentRoute.path != '/uni_login') {
                        if (VueCookies.get('ticket')) {
                            VueCookies.remove('ticket')
                            window.location.href = `${configObj.ytAdminLoginUrl}/login/out?service=${configObj.sysUrl}/uni_login`
                        } else {
                            router.replace('/uni_login').catch((err) => { })
                        }
                    }
                    break
                case 200:
                    break
                case 1003:
                case 1008:
                    VueCookies.remove('timeDifference')
                    location.reload()
                    break
                default:
                    break
            }
            return res.data
        },
        (err) => {
            Message.closeAll()
            console.log(err)
            // VueCookies.remove("token")
            // VueCookies.remove('activePath')
            // VueCookies.remove("userInfo")
            // store.commit("renewUserInfo", {});
            Message({ type: 'error', message: '网络错误，请稍后重试' })
            nprogress.done()
        }
    )

    return instance(config)
}
