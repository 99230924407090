const getFileExtension = (fileName) => {
    if (!fileName) {
        return false
    }
    return fileName.split('.').pop()
}

/**
 * 格式化字节大小
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
const formatFileSize = (bytes, si = false, dp = 1) => {
    const thresh = si ? 1000 : 1024
    if (Math.abs(bytes) < thresh) {
        return bytes + ' B'
    }
    const units = si ? ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
    let u = -1
    const r = 10 ** dp
    do {
        bytes /= thresh
        ++u
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)
    return bytes.toFixed(dp) + ' ' + units[u]
}

/**
 * 随机uuid
 * @param {*} len
 * @param {*} radix 基数
 * @returns
 */
const randomUUID = (len, radix) => {
    const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('')
    let uuid = [],
        i
    radix = radix || chars.length

    if (len) {
        // Compact form
        for (i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * radix)]
    } else {
        // rfc4122, version 4 form
        let r
        // rfc4122 requires these characters
        uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-'
        uuid[14] = '4'

        // Fill in random data.  At i==19 set the high bits of clock sequence as
        // per rfc4122, sec. 4.1.5
        for (i = 0; i < 36; i++) {
            if (!uuid[i]) {
                r = 0 | (Math.random() * 16)
                uuid[i] = chars[i == 19 ? (r & 0x3) | 0x8 : r]
            }
        }
    }
    return uuid.join('')
}

/**
 * 获取文件base64
 * @param {*} raw
 */
const getBase64 = (raw) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader()
        let base64URL = ''
        fileReader.readAsDataURL(raw)
        fileReader.onload = (e) => {
            base64URL = e.target.result
        }
        fileReader.onerror = () => {
            reject(new Error('文件流异常'))
        }
        fileReader.onloadend = () => {
            resolve(base64URL)
        }
    })
}

/**
 * blob url转file
 * 暂时只能兼容image
 * @param {*} blob
 * @returns File
 */
const blobUrlToFile = async (blobUrl) => {
    const blob = await fetch(blobUrl).then((res) => res.blob())
    const fileName = blob.type.split('/').pop()
    const file = new File([blob], `${new Date().getTime()}.${fileName}`, { type: blob.type })
    return file
}

const formatNumber = ({ num = 0, point = 1, suffix = 'w', digits = 5 } = {}) => {
    let numStr = num.toString()
    if (numStr.length < digits) {
        // 1w以内直接返回
        return numStr
    } else if (numStr.length >= digits) {
        // 转成w
        let decimal = numStr.substring(numStr.length - 4, numStr.length - 4 + point)
        return parseInt(num / 10000) + '.' + decimal + suffix
    }
}

// 检测编辑器内容是否为空
const checkEditorVal = (val) => {
    let num = 0
    //匹配空标签：格式包括1、<h1></h1> 2、<p><br></p> 3、<p style=""> </p> 3、<h1> </h1>
    const valReg = /(<([A-Za-z][A-Za-z0-9]*)>|<([A-Za-z][A-Za-z0-9]*) style="[^"]*"\/?>)+(\s+|$nbsp;|&nbsp;|\s+)+<\/([A-Za-z][A-Za-z0-9]*)>|(<([A-Za-z][A-Za-z0-9]*) style="[^"]*"\/?>|<([A-Za-z][A-Za-z0-9]*)>)(<br>)+<\/([A-Za-z][A-Za-z0-9]*)>|<([A-Za-z][A-Za-z0-9]*)><\/([A-Za-z][A-Za-z0-9]*)>|<([A-Za-z][A-Za-z0-9]*) style="[^"]*"\/?>|<\/([A-Za-z][A-Za-z0-9]*)>/g
    while (num < val.length && val != '') {
        num++
        const k = val.match(valReg)
        if (k) {
            val = val.replace(k[0], '')
        }
    }
    return val == ''
}

const getDateStr = (date) => {
    var year = date.getFullYear()
    var month = date.getMonth() + 1
    var day = date.getDate()
    month = month > 9 ? month : '0' + month
    day = day > 9 ? day : '0' + day
    return year + '-' + month + '-' + day
}

/**
 * 提取html中的文本
 * @param {*} htmlString
 * @returns text
 */
const htmlToPlainText = (htmlString) => {
    //新创建一个div
    var tempDivElement = document.createElement('div')
    tempDivElement.innerHTML = htmlString
    return tempDivElement.textContent || tempDivElement.innerText || '题目'
}

// 洗牌打乱数组
const fisherYatesShuffle = (array) => {
    let currentIndex = array.length
    let randomIndex, temp

    while (currentIndex > 0) {
        randomIndex = Math.floor(Math.random() * currentIndex)

        temp = array[currentIndex - 1]
        array[currentIndex - 1] = array[randomIndex]
        array[randomIndex] = temp

        currentIndex--
    }

    return array
}

module.exports = {
    getFileExtension,
    formatFileSize,
    randomUUID,
    getBase64,
    blobUrlToFile,
    formatNumber,
    checkEditorVal,
    getDateStr,
    htmlToPlainText,
    fisherYatesShuffle
}
